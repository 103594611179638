body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
}

.App {
  max-width: 400px;
  margin: 0 auto;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.calendar {
  max-width: 400px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-header button {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
}

.calendar-day {
  aspect-ratio: 1;
  border: 1px solid #e0e0e0;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendar-day:hover {
  background-color: #f0f0f0;
}

.calendar-day.weekday {
  font-weight: bold;
  background-color: #f5f5f5;
  border: none;
}

.calendar-day.empty {
  background-color: #fafafa;
  border: none;
}

.day-summary {
  font-size: 0.7em;
  display: flex;
  flex-direction: column;
}

.leave {
  color: #e57373;
}

.extra-time {
  color: #81c784;
}

.month-summary {
  margin-bottom: 20px;
}

.month-summary-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.calendar-day.today {
  background-color: #e0e0e0;
}

.calendar-day.sunday {
  background-color: #fff3e0;
}

.loan-coin,
.settlement-coin {
  font-size: 0.8em;
  line-height: 1;
}

.loan-coin {
  color: #ff0000;
}

.settlement-coin {
  color: #00ff00;
}