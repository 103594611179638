.employeeSelector {
    margin-bottom: 20px;
  }
  
  .selectWrapper {
    display: flex;
    gap: 10px;
  }
  
  .select {
    flex-grow: 1;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .addButton {
    padding: 8px 12px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .addButton:hover {
    background-color: #45a049;
  }
  
  .addEmployeeForm {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  
  .input {
    flex-grow: 1;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .saveButton,
  .cancelButton {
    padding: 8px 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .saveButton {
    background-color: #4CAF50;
    color: white;
  }
  
  .saveButton:hover {
    background-color: #45a049;
  }
  
  .cancelButton {
    background-color: #f44336;
    color: white;
  }
  
  .cancelButton:hover {
    background-color: #d32f2f;
  }